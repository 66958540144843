@import "normalize";
@import "bourbon/bourbon";
@import "base/base";

$accent-color: #565ed8;
$accent-fade: rgb(205, 202, 254);
$button-accent: #c05d7b;
$blackout-color: #32292f;
$magic-color: #ddd;
$dashboard-back: #d8295e;
$green-back: #98e47f;
$green-front: #171;
$further-back: white;
$level3-back: #055a57;
$level2-back: #ccc;
$level1-back: white;
$level1-under: #ddd;

$small-spacing: 0.75em;

p ~ p {
	margin-top: $small-spacing;
}

li {
	ul, ol {
		margin-bottom: 0;
	}
}

@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
	/**
	 * For IE 6/7 only
	 * Include this rule to trigger hasLayout and contain floats.
	 */
	*zoom: 1;
}

@mixin span-columns($cols) {
	width: ($cols/12 * 100%);
	float: left;
	display: block;
}

@mixin nav-column($cols) {
	width: ($cols/12 * 100%) - 1.5%;
	float: left;
	display: block;
	border-right: 1px solid #ccc;

	&:first-child {
		li a {
			font-size: 1.2em;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			padding: 0 0 0 0;

			ul {
				font-size: 80%;
				padding-left: 0;

				li a {
					padding: 3px;
					background: none;
				}
			}
		}

		li a {
			padding: 10px;
			display: block;
			background: white url(/images/chevron.png) center right / auto 1em no-repeat;
			background: white url(/images/chevron@IE.png) center right no-repeat\9;

			&:hover {
				background-color: #eee;
				text-decoration: underline;
			}

			&.selected {
				background: $accent-color url(/images/chevron-highlighted.png) center right / auto 1em no-repeat;
				background: $accent-color url(/images/chevron-highlighted@IE.png) center right no-repeat\9;
				color: white;
			}

			&.sublist {
				//color: lighten(desaturate(#1565c0, 30%), 20%);
				font-size: 80%;
				padding: 8px 10px;
				margin: 0 10px 1px;

				&::before {
					content: "–";
					margin-right: 8px;
				}
			}

			.subtitle,
			.surtitle {
				color: #888;
				font-size: 80%;
				display: block;
			}
		}
	}
}

.success {
	background: $green-back;
	padding: $small-spacing;
}

.alphabetical {
	.alphabetical--item {
		h3 {
			font-size: 1.3em;
			background: #eee;
			padding: 10px;
			margin: 0;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 10px;
		}
	}
}

a:hover {
	text-decoration: underline;
}

// General page-layout
html,body {
	//display: table;
	display: flex;
	flex-direction: column;
	height: 100vh;
	//min-height:100%;

	// Top
	> header {
		//display: table-row;
	}

	// Middle
	> section {
		//overflow: auto;
		//display: table-row;
		//width: 1024px;
	}

	// Bottom
	> footer {
		//display: table-row;
	}
}

.video-player {
	background: #eee;

	* {
		box-sizing: border-box;
	}

	.video-content {
		padding: $small-spacing;
	}

	video {
		margin: 0 auto;
		display: block;
		width: 100%;
		max-width: 854px;  // 480p
		max-height: 480px;
	}
}

.video-select-exercise {
	background: white;

	h3 {
		font-size: 12pt;	
		margin: $small-spacing 0 0;
		padding: 15px 0 5px 0;
	}

	.help {
		font-weight: normal;
		opacity: 0.5;
		font-size: 80%;
	}
}

.video-titles {
	display: table;
	width: 100%;
	list-style-type: none;
	margin: 0;
	padding-left: 0;
	border: 0;
	border-spacing: 0;
	border-collapse: collapse;

	li {
		border-right: 1px solid white;
		display: table-cell;
		text-align: center;
		vertical-align: bottom;

		a {
			display: block;
			width: 100%;
			height: 100%;
			padding: 5px 0;
			border-right: 1px solid white;
			background: #ddd;

			&:hover {
				background: $accent-color;
				color: white;
			}
		}

		&.active {
			a {
				background: #eee;

				&:hover {
					color: black;
				}
			}
		}
	}
}

@keyframes pulse {
	from {
		transform: scale3d(1, 1, 1);
	}

	50% {
		transform: scale3d(1.03, 1.03, 1.03);
	}

	to {
		transform: scale3d(1, 1, 1);
	}
}

.video-endcard {
	display: table;
	width: 100%;
	height: 480px;
	border-spacing: 0 10px;
	border-collapse: separate;

	a {
		transform: scale3d(1, 1, 1);
		transition: transform 0.6s, color 0.6s;
	}

	a:hover {
		text-decoration: none;
		background: white;
		background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,0) 69%,rgba(255,255,255,0) 100%);
		color: black;
		transform: scale3d(1.1, 1.1, 1.1);
	}

	h4, p, img {
		padding: 5px;
		margin: 0;
	}

	img {
		width: 100%;
		height: 283px;
	}

	.-next {
		animation-name: pulse;
		animation-iteration-count: infinite;
		animation-duration: 2s;
		animation-fill-mode: both;
	}

	.-next,
	.-this {
		display: table-cell;
		width: 50%;
		text-align: center;
		vertical-align: middle;
	}

	&:hover {
		// Don't pulse whilst one is hovered
		a {
			animation-name: none;
		}
	}
}

body > section {
	@include clearfix

	max-width: 64em;
	margin: 0 auto 2em;
	width: 100%;
	flex: 1 0 auto;
}

h2 {
	font-size: 2.5em;
	margin: 20px 0 10px;
}

h3 {
	font-size: 1.5em;
	margin: 0 0 0.75em;

}

.landing-page {
	margin-bottom: 20px;

	h3 {
		float: left;
		font-size: 1em;
		line-height: 1.5;
		padding-right: 10px;
		margin: 0;
	}

	p {
		margin: 0 0 10px;
	}
}

textarea {
	min-height: 200px;
	font-family: monospace;
}

.warn {
	background: #ddd;
	padding: 3px 6px;
	border-radius: 5px;
	color: red;
	opacity: 0.5;
}

@mixin nav_underlap($width) {
	width: $width;
	max-width: $width;
	overflow: hidden;
	a {
		background: linear-gradient(to right, #f6f6f6 80%, #eee);
		&.-selected {
			background: linear-gradient(to right, $accent-color 80%, darken($accent-color, 7));
		}
	}
}

.directory-browser {
	display: table;
	width: auto;
	margin-top: $small-spacing;

	nav,
	section {
		display: table-cell;
		vertical-align: top;
	}

	section {
		border-top: 10px solid white;
		padding: 0 0 0 $small-spacing;
		width: 50%;
	}

	&.-depth-3 {
		.-index-0 {
			@include nav_underlap(100px);
		}
	}

	&.-depth-4 {
		.-index-0 {
			@include nav_underlap(100px);
		}
	
		.-index-1 {
			@include nav_underlap(120px);
		}
	}

	nav {
		white-space: nowrap;
		border-right: 2px solid white;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				padding: 0 0 0 0;

				ul {
					font-size: 80%;

					li a {
						padding: 3px;
						background: none;
					}
				}
			}

			li a {
				padding: 10px 25px 10px 10px;
				margin-bottom: 2px;
				display: block;
				background: #f6f6f6 url(/images/chevron.png) center right / auto 1em no-repeat;
				background: #f6f6f6 url(/images/chevron@IE.png) center right no-repeat\9;

				&:hover {
					background-color: #eee;
					text-decoration: underline;
				}

				&.-selected {
					background: $accent-color url(/images/chevron-highlighted.png) center right / auto 1em no-repeat;
					background: $accent-color url(/images/chevron-highlighted@IE.png) center right no-repeat\9;
					color: white;
				}

				&.sublist {
					font-size: 80%;
					padding: 8px 10px;
					margin: 0 10px 1px;

					&::before {
						content: "–";
						margin-right: 8px;
					}
				}

				.subtitle,
				.surtitle {
					color: #888;
					font-size: 80%;
					display: block;
				}
			}
		}
	}
}

.sections {
	margin-top: 1.5em;
	@include clearfix;
	clear: left;

	h3 {
		margin-bottom: 5px;

		a {
			color: black;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	p {
		margin: 0.25em 0 0.5em;
	}

	nav {
		display: block;
		float: left;
		margin-right: 1.5%;
		width: 28.8%;
		padding: 1.5%;
		background: lightgrey;
		box-sizing: content-box;

		@supports (box-sizing: border-box) {
			box-sizing: border-box;
			width: 32.33%;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}
}

figure {
	width: 100%;
	max-width: 500px;
	margin: 0 auto $small-spacing;
	background: #eee;
	padding: 10px;
	text-align: center;

	figcaption {
		text-align: left;
	}

	video {
		max-width: 500px;
	}
}

table {
	width: 100%;
	max-width: 600px;
	margin: 0 auto $small-spacing;
	table-layout: auto;

	th {
		background: #333;
		color: white;
	}

	th, td {
		border: 2px solid #333;
		padding: 5px;
	}
}

button,
.button {
	&.preferred {
		background-color: #292;
		border: 3px solid #171;
		font-size: large;

		&:hover {
			background-color: #198519;
			border-color: #107010;
		}
	}

	&.strong {
		border-radius: 5px;
		background: rgba(200, 200, 200, 0.3);
		padding: 2px 6px;
	}

	&.accent {
		background: $button-accent;
		font-size: 120%;
	}

	&.danger {
		background: #9a0825;
	}
}

.error, .warning {
	background: rgb(72, 12, 20);
	color: white;
	padding: 1px $small-spacing;
	margin: 20px 0;

	&.warning {
		background: #d5b09f;
	}

	ul {
		list-style: none;
		padding: 0;
		font-size: x-small;

		li {
			margin: 5px 0;
		}

		code {
			white-space: nowrap;
			background: rgba(black,0.3);
			padding: 3px;
			border-radius: 3px;
		}
	}
}

.next-section {
	margin-top: $small-spacing;
	background: #eee;

	p {
		margin: 0 0 0 10em;
		position: relative;
		padding: 0;

		a {
			width: 10em;
			display: inline-block;
			margin-left: -10em;
			text-align: center;
			padding: $small-spacing 0;
		}

		> span {
			display: inline-block;
			vertical-align: middle;
			font-weight: bold;
			font-size: 140%;
			padding: $small-spacing 30px $small-spacing/2;

			.surtitle {
				font-weight: 100;
				font-size: 15pt;
				vertical-align: middle;
			}

			&::before {
				letter-spacing: 2px;
				display: block;
				text-transform: uppercase;
				font-weight: normal;
				font-size: 10pt;
				clear: both;
			}
		}
	}

	&.-short {
		p {
			margin-left: 8em;

			a {
				width: 8em;
				margin-left: -8em;
			}

			> span {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

	&.-level1 {
		border-bottom: 10px solid $level1-under;

		p {
			background: $level1-back;

			> span::before {
				content: "Getting started";
			}
		}
	}

	&.-level2 {
		border-bottom: 10px solid darken($level2-back, 10%);

		p {
			background: $level2-back;

			> span::before {
				content: "Getting to know more";
			}
		}
	}

	&.-level3 {
			border-bottom: 10px solid darken($level3-back, 10%);

		p {
			background: $level3-back;
			color: white;

			> span::before {
				content: "Detailed information";
			}
		}
	}

	&.-further {
			border-bottom: 10px solid darken($further-back, 10%);

		p {
			background: $further-back;
			color: black;

			> span::before {
				content: "Further reading and information";
			}
		}
	}
}

// "Commented links"
aside {
	padding: 10px;
	margin: 10px 0;
	font-size: 90%;
	background: #ddd;

	p {
		margin: 0;

		&~ p {
			margin-top: 10px;
		}
	}
}

.article-metadata {
	text-align: right;
	padding-top: 70px;
	width: 28%;
	float: right;
	background: white;
	color: #999;

	a {
		color: #777;
		text-decoration: underline;

		&:hover {
			color: black;
		}
	}
	
	p {
		font-size: 12pt;
	}

	ul {
		margin-top: 10px;
		font-size: 11pt;
		list-style: none;
	}

	h3 {
		font-size: 12pt;
		padding-bottom: 10px;
		border-bottom: 2px solid #ddd;
	}
}

main {
	width: 8/12 * 100%;
	float: left;
	@include clearfix;
	//@include span-columns(8);


	header {
		padding: 20px 20px 10px;
		margin: $small-spacing 0 $small-spacing*2;

		h2 {
			margin: 0;

			&::before {
				display: block;
				margin: 0;
				text-transform: uppercase;
				font-size: 10pt;
				font-weight: normal;
				letter-spacing: 2px;
			}
		}

		h3 {
			margin: $small-spacing 0;
		}

		.surtitle {
			font-weight: 100;
			font-size: 15pt;
			vertical-align: middle;
		}

		p {
			margin: 0;
			font-size: 14pt;
		}

		ol {
			list-style-type: decimal;
			margin-bottom: 0;

			a {
				color: white;

				&:hover {
					color: #ddd;
				}
			}
		}
	}

	counter-reset: h3;
	position: relative;

	&.level1 {
		header {
			background: $level1-back;
			border-bottom: 10px solid $level1-under;
			color: black;

			h2::before {
				content: "Getting started";
			}
		}

	}

	&.level2 {
		header {
			background: $level2-back;
			border-bottom: 10px solid darken($level2-back, 10%);
			color: black;

			h2::before {
				content: "Getting to know more";
			}
		}
	}

	&.level3 {
		header {
			background: $level3-back;
			border-bottom: 10px solid lighten($level3-back, 10%);
			color: white;

			h2::before {
				content: "Detailed information";
			}
			
			h3::before {
				counter-increment: none;
			}
		}

		h3::before {
			position: absolute;
			left: -2em;
			counter-increment: h3;
			content: counter(h3);
		}
	}

	&.further-reading {
		font-size: 110%;

		header {
			background: none;
			border-bottom: 10px solid darken($level2-back, 10%);
			color: black;

			ol a {
				color: black;
			}

			h2::before {
				content: "Further reading and information";
			}
		}

		p ~ p,
		h3 ~ p {
			border-top: 3px solid #ddd;
			padding-top: $small-spacing * 1.5;
			margin-top: $small-spacing * 1.5;
		}

		h3 {
			margin-top: $small-spacing*2;
		}

		p ~ h3 {
			border-top: 3px solid #ddd;
			padding-top: $small-spacing * 2;
			margin-top: $small-spacing * 1.5;
		}

		aside {
			background: none;
			margin: -10px 0 10px;
			padding: 0;
			font-size: 80%;

			p {
				border: 0;
				padding: 0;
				margin: 0;
			}
		}
	}

}

.search {
	p {
		margin: 0;
		font-size: 90%;
	}

	p +p {
		margin-top: 10px;
	}

	.search-match {
		font-weight: bold;
	}

	.result-breadcrumbs {
		margin: 0;
	}

	.search-result {
		background: #eee;
		padding: 10px;
		margin: 10px 0;

		h3 {
			margin: 10px 0;
		}

		h3::before {
			display: block;
			margin: 0;
			text-transform: uppercase;
			font-size: 10pt;
			font-weight: normal;
			letter-spacing: 2px;
		}

		&.-level1 h3 {
			padding: 10px;
			background: $level1-back;
			&::before {
				content: "Getting started";
			}
		}

		&.-level2 h3 {
			padding: 10px;
			background: $level2-back;
			&::before {
				content: "Getting to know more";
			}
		}

		&.-level3 h3 {
			padding: 10px;
			background: $level3-back;
			color: white;
			&::before {
				content: "Detailed information";
			}
		}
	}
}

a.start {
	display: inline-block;
	background: #292;
	padding: 10px 20px;
	font-size: 1.5em;
	border: 3px solid #171;
	border-top: 0;
	border-left: 0;
	border-radius: 5px;
	color: #fff;

	&:after {
		content: " »";
	}

	&:hover {
		background: #ddd;
		border-color: #ccc;
		text-decoration: underline;
		color:black;
	}
}

footer {
	clear: both;
	background: #eee;
	margin: 0;
	padding: 0;
	border-top: 1em solid white;
	border-top: 3em solid white\9; // IE

	p {
		padding: 2.5em 0;
		margin: 0 auto 0;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		font-size: 75%;

		a {
			margin: 0 0.5em;

			&:first-of-type {
				font-weight: bold;
			}
		}
	}

	.baoms {
		clear: left;
		display: block;
		margin-top: $small-spacing;
		text-transform: none;

		a {
			padding: 7px 0 7px 86px;
			background: transparent url(/BAOMS.png) left center / 80px auto no-repeat;
			font-weight: normal!important;
		}
	}
}

.news {
	float: right;
	box-sizing: content-box;
	background: #f9dec5;
	font-size: 90%;
	padding: 0 $small-spacing;
	width: 28%;
}

header {
	input[type=search] {
		background: white url(/images/search.png) 10px center / 12px 12px no-repeat;
		margin-top: -3.1em;
		padding-left: 30px;
		border: 2px solid black;
		border-radius: 10px;
		float: right;
		width: 12em;
	}

	div {
		padding: 20px 0;

		background: black;
		background: linear-gradient(to bottom, $blackout-color 0%, #000000 100%);

		> * {
			padding: 0 0.75em;
		}
	}

	div *,
	nav {
		margin: 0 auto;
		max-width: 64em;
	}

	h1 {
		color: white;
		line-height: 3em;
		font-size: 1.5em;
		max-width: 1024px;
		padding: 0 0 0.2em;

		a {
			text-indent: -30em;
			line-height: 42px;
			display: block;
			background: transparent url(/images/maxfacts.png);
			background: transparent url(/images/maxfacts.png) -125px 0/375px 42px no-repeat;
			height: 42px;
			width: 250px;
			color: white;
			margin: 0 auto 0 0;

			&:hover {
				color: white;
				opacity: 0.8;
			}
		}
	}

	nav {
		background: $accent-color;
		color: #eee;
		p {
			padding: $small-spacing;
			margin: 0;
		}

		a {
			color: white;
			text-decoration: underline;

			&:hover {
				color: white;
				text-shadow: 1px 1px black;
			}
		}
	}

	&.fill {
		margin-bottom: 2em;
		div {
			> * {
				padding: 0;
			}
		}

		h1 {
			font-size: 2.5em;
			margin-bottom: 0.5em;
		}

		p {
			color: white;
			margin: 0 auto;
		}
	}

	.notice {
		padding: 10px 0;
		background: #585c63;
		text-align: center;
		color: white;
		font-size:90%;
	}
}

.checkboxes {
	//@include outer-container;
	margin-bottom: 0.75em;

	label {
		//@include span-columns(4);
		//@include omega(3n);
	}
}

label.disabled {
	color: grey;
	opacity: 0.8;
}

@media only screen and (max-width: 1024px) {
	header div {
		padding: 20px;
	}

	body > section {
		padding: 0 20px;
	}
}

@media only screen and (max-width: 900px) {
	.news {
		width: 100%;
		float: none;
		padding: 1px 10px;
	}

	section > main {
		float: none;
		width: 100%;
	}

	.directory-browser {
		width: auto;
		nav {
			display: none;
		}
		&.-depth-3 {
			nav.-index-2 {
				display: block;
				
				ul {
					white-space: normal;
					li {
						display: inline;

						a {
							display: inline-block;
							padding: 1px;
							background: none;
							.subtitle {
								display: inline;
							}

							&.-selected {
								font-weight: bold;
								color:black;
 							}
						}
					}
				}
				
			}
		}

		section {
			display: block;
			width: auto;
			padding: 0;
		}
	}
}

@media only screen and (max-width: 750px) {
	.sections nav {
		float: none;
		width: 100%;
		margin-bottom: $small-spacing;
	}

	.directory-browser {
		section {
			display: block;
		}
	}
}

@media only screen and (max-width: 550px) {
	header { 
		h1 {
			text-align: center;
		}

		input {
			margin: $small-spacing auto 0!important;
			float: none!important;
			box-sizing: border-box!important;
			width: 80%!important;
		}

		form + p {
			margin-top: $small-spacing!important;
			text-align: center;
			width: 80%!important;
		}
	}
}

.link-box {
	@include clearfix

	p {
		width: 48%;
		float: left;
		clear: none;
		background: rgb(243, 241, 246);
		text-align: center;
		border: 2px solid rgb(187, 179, 204);
		border-radius: 10px;
		margin: 0 1% 10px 0;	

		a {
			font-size: 14pt;
			display: block;
			padding: 14px 0;

			&:hover {
				border-radius: 10px;
				background-color: white;
			}

			&.-pdf {
				&::first-letter {
					display: block;
					padding-left: 30px;
					background: transparent url(/images/pdf.png) 0 center/24px 24px no-repeat;
				}	
			}
		}
	}
}

.recipe {
	margin-top: 20px;
	background: #eee;
	padding: 10px 10px 10px;

	.tags {
		font-size: 90%;
		margin-bottom: 0;

		.tag {
			background: white;
			padding: 4px 8px;
			margin-right: 10px;
		}
	}
}

.search-tags {
	a {
		border-radius: 8px;
		padding: 4px 7px;	
		margin-right: 4px;
		background: white;
		box-shadow: 1px 1px 0 #bbb;

		&:hover {
			background: rgb(217, 217, 217);
		}

		&.-selected {
			background: $accent-color;
			color: white;
		}
	}
}
