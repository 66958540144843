ul,
ol {
  margin: 0 0 $small-spacing;
  padding: 0 0 0 1.5em;
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}
